import logo from './assets/images/timon_logo.png';
const config = {
    page: {
        logo: logo,
        color_base: '#212c81',
        color_titles: 'white',
        color_search_box: '#212c81',
        btn_search_footer_color: '#505050',
        btn_search_icon: '',
        path: 'http://alasdeltimoncargo.com/',
        company: 'Alas del Timón Cargo',
        address: 'Agustinas 814 nivel 2 local 27, Santiago Centro',
        email: null,
        phone: null,
        placeholderInput: 'Ingrese guia ej. atc-8000',
        prefijo: 'ATC',
    },
    connect: {
        apiKey: 'U2FsdGVkX199NA3cJ3OLSqjum9UBW7na4gs1k7Bao/j0gmS2Jca6Vg7LL8boYfhk',
        path: 'https://diboxplatform.com:2908/api/v1/tracking',
    },
    head: {
        title: (company) => `Rastrea tu envio - ${company} - Envios a desde Chile hacia Venezuela`,
    }
}

export default config;
